class ZIDXAccountAutoresponders implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountAutorespondersContainer";
    }
    getMatchURL(){
        return "/account/autoresponders";
    }
    render(){
        // console.log("autoresponders");
    }
}